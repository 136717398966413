import Text from '@basicComponents/text';
import LazyImage, {ImageUrlType} from '@basicComponents/image';
import {ModalOptions, useModal} from '@basicComponents/modal';
import theme from '@style';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {designToDp} from '@components/utils';
const {borderRadiusSize, flex, font, margin, padding} = theme;

export enum ToastType {
  success = 'success',
  warning = 'warning',
  weak = 'weak',
}
export type ToastOptionsType = ToastType | keyof typeof ToastType;
export interface ToastOptions {
  type: ToastOptionsType;
  message?: string;
  tip?: string | ReactElement;
}

const successIcon = require('@components/assets/icons/modal/success.webp');
const warningIcon = require('@components/assets/icons/modal/warning.webp');

const toastIconMap: Record<ToastType, ImageUrlType> = {
  [ToastType.success]: successIcon,
  [ToastType.warning]: warningIcon,
  [ToastType.weak]: 0,
};

const iconSize = 28;

export function useToast(
  options: ModalOptions = {backDropClose: true, delayClose: 2000},
) {
  const {...modalOptions} = options;
  const [toastInfo, setToastInfo] = useState<ToastOptions>({
    type: ToastType.success,
    message: 'ok',
    tip: '',
  });

  const {type, message, tip} = toastInfo;
  const toastIcon = toastIconMap[type];

  const isWeak = type === ToastType.weak;

  const {renderModal, show} = useModal(
    <View
      style={[
        flex.col,
        styles.toast,
        isWeak ? padding.xs : padding.l,
        flex.centerByCol,
      ]}>
      {toastIcon ? (
        <View style={[flex.row, flex.center]}>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={toastIcon}
            width={iconSize}
            height={iconSize}
          />
        </View>
      ) : (
        <></>
      )}
      <View style={[flex.col, flex.center, isWeak ? null : margin.tops]}>
        <Text calc size={'large'} style={[font.center, font.white, font.bold]}>
          {message}
        </Text>
        {React.isValidElement(tip)
          ? tip
          : !!tip && (
              <Text
                calc
                size={isWeak ? 'small' : 'default'}
                style={[font.center, font.white, margin.tops]}>
                {tip}
              </Text>
            )}
      </View>
    </View>,
    {
      ...modalOptions,
      backdropStyle: isWeak
        ? {
            backgroundColor: '#0000',
          }
        : undefined,
      overlayStyle: {
        borderRadius: borderRadiusSize.xl,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    },
  );
  const handleShow = useCallback(
    (showOptions: ToastOptions) => {
      setToastInfo(showOptions);
      show();
    },
    [show],
  );
  return {renderModal, show: handleShow};
}

const styles = StyleSheet.create({
  toast: {
    maxWidth: designToDp(296),
  },
});

export function Toast(props: {
  info: {type: string; message: string; tip?: string};
}) {
  const {type, message, tip} = props.info;
  const {renderModal, show} = useToast();
  useEffect(() => {
    show({
      type: type as any,
      message,
      tip,
    });
  }, [type, message, tip, show]);
  return renderModal;
}
