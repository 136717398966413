import React, {ReactNode, useCallback, useState} from 'react';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {BasicObject, NavigatorScreenProps, SafeAny} from '@/types';
import globalStore from '@/services/global.state';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {toGame} from '../game-navigate';
import {ActivityIndicator, SectionList, View} from 'react-native';
import {
  GameListItem,
  LiveBannerItem,
  LiveHotGameItem,
  categories,
  getBannersList,
  getCategories,
  getGameList,
  getLiveHotGameList,
  sortBy,
} from './live-casino-service';
import Text from '@/components/basic/text';
import LiveVideo from './live-casino-video';
import LiveCarousel from './live-casino-carousel';
import LiveFilter from './live-casino-filter';
import LiveListItem from './live-casino-list-item';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {useFocusEffect} from '@react-navigation/native';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {goBack} from '@/utils';

const pageSize = 21;

function pairElements(arr: SafeAny[]) {
  const result = [];

  // 遍历数组，步长为2
  for (let i = 0; i < arr.length; i += 2) {
    // 取两个元素并组成一对
    const pair = [arr[i], i + 1 < arr.length ? arr[i + 1] : null];
    result.push(pair);
  }
  return result;
}

function nElements(arr: SafeAny[], n: number) {
  const result = [];
  let i = 0;
  while (arr.length > i * n) {
    result.push(arr.slice(i * n, ++i * n));
  }
  return result;
}

const LiveCasino = (
  props: NavigatorScreenProps & {renderAmount?: ReactNode},
) => {
  const {calcActualSize} = useScreenSize();
  const {route, renderAmount} = props;
  // categoryId在Singam和Dailylotto都是默认6为Live Casino，故这里如果未传入则先使用默认值
  const categoryId = (route.params as BasicObject)?.categoryId || '6';
  const [pageLoading, setPageLoading] = React.useState(false);
  const [categoryFilter, setCategoryFilter] = React.useState('');
  const [sortFilter, setSortFilter] = React.useState('');
  const [bannerList, setBannerList] = useState<LiveBannerItem[]>([]);
  const [hotGameList, setHotGameList] = useState<LiveHotGameItem[]>([]);
  const [gameList, setGameList] = useState<GameListItem[][]>([]);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const pageRef = React.useRef(1);
  const onEndReachedCalledDuringMomentum = React.useRef(true);
  const isOld = false;
  const [hasMore, setHasMore] = React.useState(true);
  const firstLock = React.useRef(true);
  const [scrollY, setScrollY] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(200);
  const [dynamicCategories, setDynamicCategories] =
    useState<BasicObject[]>(categories);

  const initAmount = useCallback(() => {
    globalStore.token && globalStore.updateAmount.next({});
  }, []);
  useFocusEffect(initAmount);

  React.useEffect(() => {
    initGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter, sortFilter]);

  const loadGameList = async (refresh: boolean, old: boolean) => {
    try {
      if (pageLoading || loadMore) {
        return;
      }
      if (refresh) {
        pageRef.current = 1;
        setPageLoading(true);
      } else {
        pageRef.current += 1;
        setLoadMore(true);
      }
      const res = await getGameList({
        categoryId,
        gameType: categoryFilter,
        orderByField: sortFilter ? 'name' : undefined,
        isAsc: sortFilter ? sortFilter === '1' : undefined,
        pageNo: pageRef.current,
        pageSize,
      });
      if (res.content) {
        const newArr = old
          ? pairElements(res.content)
          : nElements(res.content, 3);
        setGameList(refresh ? newArr : gameList.concat(newArr));
        setHasMore(res.content.length >= pageSize);
      }
    } finally {
      setPageLoading(false);
      setLoadMore(false);
    }
  };

  const onLoadMore = async () => {
    if (hasMore && !onEndReachedCalledDuringMomentum.current) {
      await loadGameList(false, isOld);
      onEndReachedCalledDuringMomentum.current = true;
    }
  };

  const initGames = async () => {
    setPageLoading(true);
    try {
      const _categories = await getCategories(categoryId);
      setDynamicCategories([
        {
          value: '',
          label: 'ALL',
        },
        ..._categories.map(name => ({
          value: name,
          label: name,
        })),
      ]);
      const [game, banner, hot] = await Promise.allSettled([
        getGameList({
          categoryId,
          gameType: '',
          orderByField: undefined,
          isAsc: undefined,
          pageNo: 1,
        }),
        getBannersList(),
        getLiveHotGameList(),
      ]);
      if (game.status === 'fulfilled') {
        const newArr = isOld
          ? pairElements(game.value.content)
          : nElements(game.value.content, 3);
        setGameList(newArr);
      }
      if (banner.status === 'fulfilled' && banner.value?.length > 0) {
        setBannerList(banner.value);
        // 兼容新老接口
        loadGameList(true, isOld);
      } else {
        setBannerList([]);
      }
      setHotGameList(hot.status === 'fulfilled' ? hot.value : []);
    } finally {
      setPageLoading(false);
      firstLock.current = false;
    }
  };

  const Footer = React.useMemo(() => {
    if (loadMore) {
      return (
        <View style={[theme.flex.center, theme.padding.l]}>
          <ActivityIndicator />
        </View>
      );
    }
    if (gameList.length > 0 && !hasMore) {
      return (
        <View style={[theme.padding.btml]}>
          <Text textAlign="center" size="medium" color={'#9FA5AC'}>
            No more data
          </Text>
        </View>
      );
    }
    return null;
  }, [loadMore, gameList, hasMore]);

  // 兼容处理 videoUri
  const getMp4 = (item: LiveBannerItem) =>
    item.bannerImg.endsWith('mp4') ? item.bannerImg : item.otherParams;
  const videoUri = bannerList.length > 0 ? getMp4(bannerList[0]) : '';
  return (
    <View style={[theme.flex.flex1, theme.background.lightGrey]}>
      <DetailNavTitle
        // hideTitle={true}
        title={'Live'}
        hideAmount={!!renderAmount}
        rightNode={renderAmount}
        hideServer={!isOld}
        onBack={goBack}
      />
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <SectionList
            onScroll={e => {
              if (
                globalStore.isWeb &&
                onEndReachedCalledDuringMomentum.current
              ) {
                onEndReachedCalledDuringMomentum.current = false;
              }
              setScrollY(e.nativeEvent.contentOffset.y);
            }}
            // removeClippedSubviews={false}
            sections={[
              {
                title: '',
                data: gameList,
              },
            ]}
            // columnWrapperStyle={[{columnGap: 8}, theme.padding.lrl]}
            ListHeaderComponent={
              <View
                onLayout={e => setHeaderHeight(e.nativeEvent.layout.height)}>
                <LiveVideo
                  onPress={i => {
                    const current = bannerList[i];
                    toGame({
                      source: current.source,
                      id: current.gameId,
                      gameUrl: current.gameLink,
                      tripartiteUniqueness: current.tripartiteUniqueness,
                    });
                  }}
                  videoUri={videoUri}
                />
                <LiveCarousel
                  onPress={(info: BasicObject) =>
                    toGame({
                      source: info.source,
                      name: info.name || '',
                      id: info.gameId,
                      gameUrl: info.gameLink,
                      tripartiteUniqueness: info.tripartiteUniqueness,
                    })
                  }
                  bannerList={hotGameList}
                />
              </View>
            }
            stickySectionHeadersEnabled
            renderSectionHeader={({section: {}}) =>
              isOld ? (
                <LiveFilter
                  sortBy={sortBy}
                  currentCategory={categoryFilter}
                  currentSort={sortFilter}
                  categories={dynamicCategories}
                  onChange={(type: string, value: string) => {
                    if (type === 'Category') {
                      setCategoryFilter(value);
                    } else {
                      setSortFilter(value);
                    }
                  }}
                />
              ) : null
            }
            renderItem={({item, index}) => {
              return isOld ? (
                <View
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={[theme.flex.row, theme.padding.lrl, {columnGap: 8}]}>
                  {item
                    .filter(pie => pie)
                    .map((pie, i) => (
                      <ExposureWrap
                        key={i}
                        message={`GAMETYPE_LIVE_${index * 3 + i + 1}_EXPOSURE`}>
                        <LiveListItem
                          pageLoading={pageLoading}
                          headerHeight={headerHeight}
                          scrollY={scrollY}
                          index={index}
                          onPress={() => {
                            trackClick(
                              `GAMETYPE_LIVE_${index * 3 + i + 1}_TAP`,
                            );
                            toGame({
                              source: pie.source,
                              name: pie.name,
                              id: pie.id,
                              gameUrl: pie.gameUrl,
                              tripartiteUniqueness: pie.tripartiteUniqueness,
                            });
                          }}
                          gameInfo={pie}
                        />
                      </ExposureWrap>
                    ))}
                </View>
              ) : (
                <View
                  style={[
                    theme.flex.row,
                    {
                      paddingHorizontal: calcActualSize(theme.paddingSize.l),
                      columnGap: calcActualSize(theme.paddingSize.s),
                    },
                  ]}>
                  {item
                    .filter(pie => pie)
                    .map((pie, i) => (
                      <ExposureWrap
                        key={i}
                        message={`GAMETYPE_LIVE_${index * 3 + i + 1}_EXPOSURE`}>
                        <LiveListItem
                          isOld={isOld}
                          pageLoading={pageLoading}
                          headerHeight={headerHeight}
                          scrollY={scrollY}
                          index={index}
                          onPress={() => {
                            trackClick(
                              `GAMETYPE_LIVE_${index * 3 + i + 1}_TAP`,
                            );
                            toGame({
                              source: pie.source,
                              name: pie.name,
                              id: pie.id,
                              gameUrl: pie.gameUrl,
                              tripartiteUniqueness: pie.tripartiteUniqueness,
                            });
                          }}
                          gameInfo={pie}
                        />
                      </ExposureWrap>
                    ))}
                </View>
              );
            }}
            keyExtractor={(item, index) => `${index}`}
            onEndReachedThreshold={0.2}
            onMomentumScrollBegin={() => {
              onEndReachedCalledDuringMomentum.current = false;
            }}
            onEndReached={onLoadMore}
            ListFooterComponent={Footer}
          />
        </View>
      </Spin>
    </View>
  );
};

export default LiveCasino;
