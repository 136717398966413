import React, {useEffect, useRef} from 'react';
import {Image} from 'antd-mobile';
import DetailNavTitle from '../detail-nav-title';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
interface AccountWrapProps {
  isClose?: boolean;
  title?: string;
  children?: React.ReactNode;
  onBackClick?: () => void;
}

const AccountWrap = ({
  children,
  onBackClick,
  isClose,
  title,
}: AccountWrapProps) => {
  const {screenHeight, calcActualSize} = useScreenSize();
  const prevHeight = useRef(screenHeight);
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (prevHeight.current - screenHeight > 100) {
      console.log('keyboard show');
      scrollRef.current?.scrollTo({
        top: calcActualSize(10.5 * 16 - 12),
        behavior: 'smooth',
      });
    }
    prevHeight.current = screenHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenHeight]);
  return (
    <div className="flex flex-col w-full h-[100vh] overflow-hidden">
      <DetailNavTitle
        hideAmount
        hideServer
        title={title}
        onBack={!isClose ? onBackClick : undefined}
        onClose={isClose ? onBackClick : undefined}
      />
      <div className="flex flex-col flex-1 relative overflow-hidden bg-white">
        <Image
          width={'102%'}
          className="absolute top-0 -left-1"
          src={require('@/assets/imgs/account-wrap/account-top-bg.webp')}
        />
        <div
          className="flex flex-col flex-1 bg-white overflow-y-auto overflow-x-hidden"
          ref={scrollRef}>
          <div className="mt-[10.5rem] mx-3 bg-white rounded-t-xl flex flex-col z-10">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountWrap;
