import {Image} from 'antd-mobile';
import React from 'react';
import {goCS} from '@utils';
import service from '@assets/imgs/home/new-service.webp';
const HomeService = () => {
  return (
    <div
      className="bg-transparent rounded-full size-16 flex items-center justify-center"
      onClick={goCS}>
      <Image
        src={service}
        style={{
          height: '3.25rem',
          width: '3.25rem',
          background: 'white',
          borderRadius: '50%',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
        }}
      />
    </div>
  );
};

export default HomeService;
