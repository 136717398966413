import React from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import HomeBottomCard from '@/components/business/home-bottom-card/home-bottom-card';
import social1 from '@assets/imgs/home/social1.png';
import social2 from '@assets/imgs/home/social2.webp';
import social3 from '@assets/imgs/home/social3.webp';
import social4 from '@assets/imgs/home/social4.webp';

const HomeSocial = () => {
  const {t} = useTranslation();
  const imgs = [social1, social2, social3, social4];

  // 添加社交媒体链接
  const socialLinks = [
    'https://www.instagram.com/indusbet/',
    'https://whatsapp.com/channel/0029VarORo10AgW5mWcnFN0N',
    'https://t.me/indusbetcare',
    'https://www.youtube.com/@Indusbet',
  ];

  return (
    <HomeBottomCard
      title={t('home.label.social')}
      gap={'gap-3'}
      imgs={imgs}
      renderItem={(url, index) => (
        <div
          className="h-[1.75rem] cursor-pointer"
          onClick={() => (window.location.href = socialLinks[index])}>
          <Image src={url} height={'1.75rem'} width={'1.75rem'} />
        </div>
      )}
    />
  );
};

export default HomeSocial;
