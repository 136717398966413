import theme from '@style';
import {
  MeListItem,
  customerServiceIcon,
  languagesIcon,
  moneyIcon,
  notificationsIcon,
  passwordIcon,
  resultHistoryIcon,
  transactionsIcon,
  updateIcon,
} from '@businessComponents/list-item';
import React from 'react';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {NoMoreData} from '@basicComponents/default-page';
import globalStore from '@/services/global.state';
import {
  goCS,
  goTo,
  toAgentApply,
  inApp,
  getVersionName,
  copyText,
} from '@/utils';
import {useConfirm} from '@basicComponents/modal';
import {useLanguageModal} from '@businessComponents/language';
import {useInnerStyle} from './me.hooks';
import {IUserInfo, MessageCountInfo} from '@/services/global.service';
import Tag from '@basicComponents/tag';
import {useTranslation} from 'react-i18next';
import {checkUpdate} from '@/utils/update';
import {setUniqueId} from '@/utils/moengage';
import {ReactComponent as EmailIcon} from '@assets/icons/me/email.svg';
export interface MeListsProps {
  messageCountInfo: MessageCountInfo;
  showNoMenu: boolean;
  login: boolean;
  user?: IUserInfo;
}

const MeLists: React.FC<MeListsProps> = ({login, messageCountInfo, user}) => {
  const {i18n} = useTranslation();
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm();
  const {renderModal: renderLanguageModal, show: languageShow} =
    useLanguageModal();
  const {listStyle} = useInnerStyle();

  const toResults = () => {
    goTo('Result');
    // postMessage('tabbar:/pages/results/results');
  };

  const toTransactions = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Transactions');
  };

  const toMyBets = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Bets');
  };

  const toNotify = () => {
    // 跳转notify
    // console.log('目前没有notify，且先不上');
    // globalStore.globalTotal.next(notYetWarning);
    if (!login) {
      goTo('Login');
    } else {
      goTo('Notification');
    }
  };

  const toLanguage = () => {
    languageShow();
  };

  const doLogout = () => {
    // logout
    confirmShow(i18n.t('alert.logout'), i18n.t('alert.sureLogout'), () => {
      setUniqueId('');
      globalStore.token = null;
      localStorage.removeItem('imCount');
      globalStore.chatNumSubject.next(0);
      goTo('Login');
    });
  };

  const toSetPassword = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('SetPassword');
  };

  const toAgency = () => {
    if (user?.isAgent === 1) {
      goTo('ProxyHome');
    } else {
      toAgentApply();
    }
  };
  return (
    <View style={[theme.padding.btmxxl, theme.background.lightGrey]}>
      {/* 列表区域 */}
      <View
        style={[
          theme.background.white,
          theme.padding.lrm,
          theme.padding.tbl,
          theme.borderRadius.m,
          theme.overflow.hidden,
          theme.margin.btml,
        ]}>
        <MeListItem
          icon={resultHistoryIcon}
          title={i18n.t('me.bottom.resultHistory')}
          onPress={toResults}
        />
        <MeListItem
          icon={require('@assets/icons/me/proxy.webp')}
          title={i18n.t('me.bottom.proxy')}
          rightContent={
            <View
              style={[theme.flex.center, theme.padding.lrxs, listStyle.new]}>
              <Text fontSize={8} style={[theme.font.white]}>
                {i18n.t('me.new')}
              </Text>
            </View>
          }
          onPress={toAgency}
        />

        <MeListItem
          icon={moneyIcon}
          title={i18n.t('me.bottom.myBets')}
          onPress={toMyBets}
        />

        <MeListItem
          icon={transactionsIcon}
          title={i18n.t('me.bottom.myTransactions')}
          onPress={toTransactions}
        />
      </View>
      <View
        style={[
          theme.background.white,
          theme.padding.lrm,
          theme.padding.tbl,
          theme.borderRadius.m,
          theme.overflow.hidden,
          theme.margin.btml,
        ]}>
        <MeListItem
          icon={notificationsIcon}
          title={i18n.t('me.bottom.notify')}
          rightContent={
            messageCountInfo && messageCountInfo.messageTotalCount ? (
              <Tag content={messageCountInfo.messageTotalCount} />
            ) : null
          }
          onPress={toNotify}
        />
        <MeListItem
          icon={passwordIcon}
          title={i18n.t('me.bottom.password')}
          onPress={toSetPassword}
        />
        <MeListItem
          icon={languagesIcon}
          title={i18n.t('me.bottom.lang')}
          onPress={toLanguage}
        />
        <MeListItem
          icon={customerServiceIcon}
          title={i18n.t('me.bottom.customer')}
          onPress={goCS}
        />
        {inApp && (
          <MeListItem
            icon={updateIcon}
            title={i18n.t('me.bottom.update')}
            rightContent={
              <Text style={[theme.font.secAccent, theme.font.fs]}>
                {getVersionName()}
              </Text>
            }
            onPress={() => {
              checkUpdate(true);
            }}
          />
        )}
        <div className="flex flex-col text-xs items-center py-3 gap-2 border-t border-t-line">
          <p>{i18n.t('me.tip.anyquery')}</p>
          <p
            className="text-c1 flex flex-row gap-2 text-sm items-center"
            onClick={() => {
              copyText('indusbetfeedback@gmail.com');
              globalStore.globalTotal.next({
                type: 'success',
                message: i18n.t('tip.copysuccess'),
              });
            }}>
            <EmailIcon className="size-4 text-c1" />
            <span>indusbetfeedback@gmail.com</span>
          </p>
        </div>
      </View>
      {login && (
        <TouchableOpacity>
          <View
            style={[
              theme.background.white,
              theme.padding.lrm,
              theme.padding.tbl,
              theme.borderRadius.m,
              theme.overflow.hidden,
              theme.margin.btmxxl,
            ]}>
            <Text
              blod
              second
              fontSize={theme.fontSize.m}
              style={[theme.font.center]}
              onPress={doLogout}>
              {i18n.t('me.bottom.logout')}
            </Text>
          </View>
        </TouchableOpacity>
      )}

      <NoMoreData text="" />
      {renderConfirmModal}
      {renderLanguageModal}
    </View>
  );
};

export default MeLists;
