import {http, indusWinHttp, sportsHttp} from '@utils';
import {
  ScratchListItem,
  NoticeCheckList,
  BannerListItem,
  HotGameItem,
  KeralaListItem,
  DigitListItem,
  DiceListItem,
  MatkaListItem,
  LiveHotGameItem,
  LiveGameItem,
  LiveGameListParams,
  ColorListItem,
  HomeGameParams,
  HomeGameFloorItem,
  HomeGoldGame,
  SportItem,
} from './home.type';
import globalStore from '@/services/global.state';
import {SafeAny} from '@/types';

/** 获取刮刮乐入口列表 */
export const getScratchList = () => {
  return indusWinHttp.get<null, ScratchListItem[]>(
    'iGaming/api/scratchoffGameList',
  );
};

/** 获取活动入口 */
export const getNoticeCheck = () => {
  return http.post<null, NoticeCheckList>('app/sys/notice/check');
};

/** 顶部banner */
export const getBannerList = (type = 2) => {
  return http.post<{type: number}, BannerListItem[]>('app/banner/manage/list', {
    type,
  });
};

/** 热门小游戏 */
export const getHotGameList = () => {
  return http.post<null, HotGameItem[]>('app/hotGame/list');
};

/** kerala列表 */
export const getKeralaList = () => {
  return http.post<{type: number}, KeralaListItem[]>(
    'app/lottery/type/kerala/list/new',
    {type: 1},
  );
};

/** 3Digit列表 */
export const getDigitList = () => {
  return http.post<null, DigitListItem[]>('app/pick/info/list');
};

/** Dice列表 */
export const getDiceList = () => {
  return http.post<null, DiceListItem[]>('app/diceThree/homeList');
};
/** color列表 */
export const getColorList = () => {
  return http.post<null, ColorListItem[]>('app/redGreen/config/getAll');
};

/** Matka列表 */
export const getMatkaList = () => {
  return http.post<null, MatkaListItem[]>('app/matka/lottery/home/list');
};

export const getLiveHotGameList = () => {
  return indusWinHttp.get<null, LiveHotGameItem[]>('iGaming/liveHotGameList');
};

/**
 * 首页视讯游戏列表
 * @param gameName 游戏名称（筛选）
 * @param sort 可不传 0 desc 1 asc
 */
export function getLiveGameList(gameName?: string, sort?: number) {
  return indusWinHttp.get<LiveGameListParams, LiveGameItem[]>(
    'iGaming/liveGameList',
    {
      params: {
        gameName,
        sort,
      },
    },
  );
}

export function getNotices() {
  return http.post<null, string[]>('app/h5/getNotices');
}

export const getSprotUrl = () => {
  return http.get<null, string>('sport/sap/start');
};

/** Home金刚去游戏列表 */
export const getHomeGoldGames = () => {
  return http.post<{groupCode: 'KING_KONG'}, HomeGoldGame[]>(
    'app/moduleTypes/getAllListBySort',
    {
      groupCode: 'KING_KONG',
    },
  );
};

export const getSideList = () => {
  return http.post<{groupCode: 'SUSPENSION_APP'}, HomeGoldGame[]>(
    'app/moduleTypes/getAllListBySort',
    {
      groupCode: 'SUSPENSION_APP',
    },
  );
};

/** Home游戏板块列表 */
export const getHomeGames = () => {
  return http.post<HomeGameParams, HomeGameFloorItem[]>(
    'app/homeGamesCategory/getAllListBySortV2',
    {
      position: 6,
    },
  );
};

export const getSportGames = () => {
  return http.post<{groupCode: string}, SportItem[]>(
    'app/moduleTypes/getAllListBySort',
    {
      groupCode: 'SPORT_GAME',
    },
  );
};

export const getGameURL = (gameType: string) => {
  return indusWinHttp.get<string, string>(
    `sport/sportGame/getGameUrl?gameType=${gameType}`,
  );
};

export const getBetBy = () => {
  return indusWinHttp.get<null, {brandId: string; token: string}>(
    `sport/betby/getToken?lang=${
      globalStore.lang ? globalStore.lang.split('_')[0] : 'en'
    }`,
  );
};

export const getGR8Token = () => {
  return sportsHttp.get<null, SafeAny>('gr8/api/v1/auth/get-jwt-token');
};
