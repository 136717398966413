import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject} from '@types';
import HomeService from './home-service';
import React, {useCallback, useState} from 'react';
import {View} from 'react-native';
import {useInnerStyle} from '../home.hooks';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
import {useFocusEffect} from '@react-navigation/native';
import {getTaskCount} from '@/pages/promotions/service';
import {useLogin} from '@/utils/state-hooks';
import HomeSideBonus from './home-side-bonus';
import {goTo} from '@/utils';

const shadow = {
  startColor: 'rgba(0, 0, 0, 0.25)',
  distance: 4,
  offset: [0, 4],
  style: [theme.borderRadius.m, theme.overflow.hidden] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  });
}

const HomeSide = () => {
  const {homeSideStyles} = useInnerStyle();
  const [taskCount, setTaskCount] = useState(0);
  const login = useLogin();

  useFocusEffect(
    useCallback(() => {
      if (!login) {
        return;
      }
      getTaskCount().then(data => {
        setTaskCount(data);
      });
    }, [login]),
  );

  const handleBonusClick = () => {
    trackClick('HOME_FLOATINGWINDOW_EVENTS_EXPOSURE');
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('PromotionsV2', {tabKey: 'bonus'});
  };

  return (
    <View style={[theme.position.abs, homeSideStyles.wrap]}>
      <ExposureWrap message="HOME_FLOATINGWINDOW_EVENTS_EXPOSURE">
        <HomeSideBonus count={taskCount} onClick={handleBonusClick} />
      </ExposureWrap>
      <HomeService />
    </View>
  );
};
export default HomeSide;
