export default {
  loading: 'Loading...',
  'no-update': 'Already the latest version',
  'load-more': 'Load More',
  'copy-success': 'Copy Successfully',
  'invalid-url': 'Invalid Url',
  dicePopular: 'The most popular dice game',
  indianLottery: 'Indian official lottery',
  jackpot: 'JACKPOT',
  home: {
    tab: {
      home: 'Home',
      result: 'Result',
      casino: 'Casino',
      scratch: 'Scratch',
      scratchOff: 'Scratch Off',
      referral: 'Referral',
      me: 'Me',
      live: 'Live',
      liveCasino: 'Live Casino',
      sports: 'Sports',
      lottery: 'Lottery',
      promotions: 'Promotions',
      invite: 'Invite',
      wallet: 'Wallet',
      proxyhome: 'Agent',
      chat: 'Chat',
    },
    scratch: {
      title: 'Scratch',
      free: 'FREE',
    },
    kerala: {
      title: 'Kerala State Lottery',
      no: 'NO.',
      lastResult: 'Last Jackpot Outcome',
    },
    digit: {
      title: '3 Digit Game',
      booking: 'Time for Next Booking',
      ticket: 'Ticket',
      price: 'Ticket Price',
      lastResult: 'Last Draw Outcome',
      drawTime: 'Next Draw Time',
    },
    dice: {
      title: 'Dice Game',
      play: 'Play Now',
    },
    color: {
      title: 'Color Prediction',
    },
    matka: {
      bid: 'BID',
      win: 'WIN',
      OPEN: 'OPEN',
      CLOSE: 'CLOSE',
      open: 'Open',
      close: 'Close',
      title: 'Satta Matka',
    },
    'live-casino': {
      title: 'Live Casino',
    },
    car: {
      title: 'Quick Race',
      min: 'min',
      text: 'Next draw starts in',
    },
    tip: {
      closed: '{{name}} is closed now, please choose next game',
      copied: 'Copy successfully, please share with friends',
    },
    label: {
      menu: 'Menu',
      viewAll: 'View All',
      buyNow: 'Buy Now',
      'lucky-spin': 'Lucky Spin',
      rebate: 'Rebate',
      'free-lottery': 'Free Lottery',
      referral: 'Referral',
      'refer-friend': 'Refer Friend',
      agent: 'Agent',
      biggestWins: 'Biggest Wins',
      jackpotGames: 'Jackpot Games',
      onlineUser: '{{number}} users playing',
      popularGame: 'The most popular game',
      provedores: 'Provedores',
      allGames: 'All Games',
      more: 'More',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      total: 'Total',
      bet: 'Bet',
      online: 'Online',
      winningAmount: 'Winning Amount',
      imgFail: 'Image failed to load',
      partners: 'Trusted Sponsors & Partners Of',
      pays: 'Available payment methods',
      social: 'Social Networks',
      licenses: 'Licenses',
      promotions: 'Promotions',
      invite: 'Invite',
      agency: 'Agency',
      luckySpin: 'Lucky Spin',
      chat: 'Service',
      all: 'All',
      availOutcomes: '{{count}} Available Outcomes',
      downText: 'Add to Desktop',
    },
    luckyspin: {
      add: 'Add',
      spin: 'SPIN',
      won: 'Won',
      winner: 'Big Winners',
      mySpin: 'My Spin',
    },
    title: {
      scratchOff: 'Scratch Off',
      casino: 'Casino',
      live: 'Live',
      sports: 'Sports',
      lottery: 'Lottery',
      scratch: 'Scratch',
      servertime: 'Server Uptime Days',
      winningInformation: 'Winning Information',
      todayEarningsChart: "Today's earnings chart",
    },
  },
  casino: {
    sortby: 'Sort by',
    categories: 'Categories',
    popular: 'Popular',
    all: 'All',
    lobby: 'Lobby',
  },
  me: {
    label: {
      rechargeRecord: 'Recharge records',
      withdrawRecord: 'Withdrawal records',
      check: 'Check',
    },
    webview: {
      vip: 'Member Center',
    },
    new: 'NEW',
    user: {
      userId: 'User ID',
      moreExciting: 'More exciting after logging in',
      login: 'Log in',
      loginUpper: 'LOGIN',
      uid: 'UID:',
      lastLogin: 'Last login:',
    },
    money: {
      totalWallet: 'Total Wallet',
      myWallet: 'My Wallet',
      cashBalance: 'Cash Balance',
      withdrawAmount: 'Withdrawable Amount',
      totalBalance: 'Total balance',
      wallet: 'Wallet',
      recharge: 'Recharge',
      withdraw: 'Withdraw',
    },
    vip: {
      recharge: 'Recharge',
      move: 'more to reach level',
    },
    bottom: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      transfer: 'Transfer',
      proxy: 'Agency Center',
      logout: 'LOG OUT',
      resultHistory: 'Result history',
      myBets: 'My Bets',
      myTransactions: 'My Transactions',
      commission: 'My Commission',
      notify: 'Notifications',
      lang: 'Languages',
      customer: 'Customer service',
      update: 'Update',
      password: 'Password',
    },
    language: {
      switch: 'Switch Language',
      english: 'English',
    },
    tip: {
      cashTitle: 'Cash Balance:',
      cashContent:
        '"Cash balance" refers to the actual cash funds available in your account that can be used for betting or other transactions.',
      withdrawTitle: 'Withdrawable Amount:',
      withdrawContent:
        '"Withdrawable amount" is the portion of your cash balance that can be withdrawn from your account. It represents the money that has met the wagering requirements, which need to be fulfilled before the funds can be withdrawn.',
      dailyRewards: 'Exciting rewards await, act now!',
      bonus: 'Win <0>free</0> prizes, claim your reward now!',
      anyquery: 'For any queries and complaints please email us',
    },
  },
  invitation: {
    title: {
      singUp: 'Free lottery tickets',
    },
    label: {
      claim: 'Claim your Ticket',
    },
  },
  push: {
    mainText: 'This website would like to send you awesome updates and offers!',
    secText: 'Notifications can be turned off anytime from browser settings.',
    allow: 'Allow',
    block: "Don't Allow",
  },
  promotionsV2: {
    bonus: 'Bonus',
    reward: 'Reward',
    promotion: 'Promotions',
    history: 'History',
    climb: 'Climb',
    receive: 'Receive',
    received: 'Received',
    expired: 'Expired',
    joined: '{{num}} Joined',
    join: 'Join to win',
    claimTime: 'Claim Time',
    depositAmount: 'Deposit Amount',
    bonusAmount: 'Bonus Amount',
    status: 'Status',
    congratulations: 'Congratulations !',
    getBonus: 'Get Bonus',
    claimNow: 'Claim Now',
    taskList: 'Task List',
    remainTime: 'Remaining Time',
    joinNow: 'Join Now',
    rules: 'Event Rules',
    vipLevel: 'VIP Level',
    rechargeAmount: 'Recharge Amount',
    withdrawAmount: 'Withdraw Amount',
    rechargeBonus: 'Recharge Bonus',
    details: 'Event Details',
    activityTime: 'Activity Time',
    yourCurrentRecharge: 'Your Current Recharge',
    eventRewards: 'Event Rewards',
    cumuBets: 'Cumulative Bets',
    rechargeCount: 'Recharge Count',
    withdrawCount: 'Withdraw Count',
    prize: {
      ticket: 'Kerala lottery',
      spin: 'Lucky Spin',
    },
    type: {
      recharge: 'Recharge',
      withdraw: 'Withdraw',
      bet: 'Bet',
    },
    times: {
      1: '1st',
      2: '2nd',
      3: '3rd',
      4: '4th',
    },
    tip: {
      claim: 'Bet <0>{{amount}}</0> more to claim bonus',
      required: '<0>{{multiple}}</0> Betting Required',
      expired: 'The event has ended',
      withdraw: 'Withdraw immediately to earn',
      delete: 'Are you sure you want to delete this bonus?',
      deleteSuccess: 'Delete Successfully',
      toReceive:
        '{{type}} another <0>{{rechargeAmount}}</0> to receive a <0>{{bonusAmount}}</0> bonus',
      timeToReceive:
        '{{type}} another <0>{{rechargeAmount}}</0> more times to receive a <0>{{bonusAmount}}</0> bonus',
      completedRecharge: 'You have completed your {{times}} recharge',
      notCompleteRechargeTitle: '{{times}} Recharge',
      currentRechargeTitle: 'Your {{times}} Recharge',
      rechargeToGet: 'Recharge {{amountRange}} to get a reward of {{bonus}}',
      mustComplete:
        'You must complete the {{currentCount}} recharge to participate in the {{nextCount}} recharge event',
      notLogin: 'Please login to participate in this event',
    },
  },
};
