import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';

import 'swiper/css';
import SportSwiperItem, {SportSwiperItemProps} from './swiper-item';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface SportSwiperProps {
  list: SportSwiperItemProps[];
  isLive?: boolean;
}

const SportSwiper = ({list, isLive}: SportSwiperProps) => {
  const {calcActualSize} = useScreenSize();
  return (
    <div className="px-3">
      <Swiper
        spaceBetween={calcActualSize(6)}
        slidesPerView={'auto'}
        className="w-full mb-3"
        modules={[Autoplay]}
        loop
        autoplay={{delay: 4000}}>
        {list.map((item, index) => (
          <SwiperSlide className="w-[19rem]" key={index}>
            <SportSwiperItem isLive={isLive} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SportSwiper;
