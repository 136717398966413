import {http} from '@/utils';
import dayjs from 'dayjs';

export interface DiceHomeItem {
  cycle: number;
  configId: number;
  id: number;
}

export interface PageInfo {
  pageNo: number;
  pageSize?: number;
}

export interface DiceResultItem {
  bigOrSmall: number;
  issNo: string;
  oddOrEven: number;
  openTime: number;
  // 结果是按照逗号隔开的，目前暂定1、4是红色的，其他都是黑色的，后面再说
  result: string;
  totalCount: number;
}

export interface DiceParams extends PageInfo {
  configId: number;
}

export interface DiceResult {
  content: DiceResultItem[];
  totalPages: number;
  totalSize: number;
}

export interface DiceCurrentResultItem {
  configId: number;
  countdownTime: number;
  currentTime: number;
  cycle: number;
  dataStatus: number;
  id: number;
  issueNo: string;
  minRemainingSecond: number;
  openTime: number;
  status: number;
}

export interface OptionOdds {
  sum_small: string;
  sum_even: string;
  double_six: string;
  single_five: string;
  double_one: string;
  leopard_six: string;
  sum_big: string;
  sum_seventeen: string;
  sum_twelve: string;
  sum_eight: string;
  sum_four: string;
  leopard_two: string;
  single_three: string;
  single_six: string;
  sum_fifteen: string;
  sum_seven: string;
  single_one: string;
  sum_fourteen: string;
  double_four: string;
  sum_thirteen: string;
  sum_sixteen: string;
  leopard_three: string;
  leopard_five: string;
  double_three: string;
  sum_eighteen: string;
  sum_nine: string;
  sum_ten: string;
  double_five: string;
  sum_five: string;
  sum_six: string;
  double_two: string;
  single_two: string;
  leopard_four: string;
  leopard_any: string;
  sum_three: string;
  leopard_one: string;
  sum_eleven: string;
  sum_odd: string;
  single_four: string;
  [key: string]: string;
}

export interface DiceConfigInfo {
  ruleLinkUrl: string;
  videoLinkUrl: string;
  cycle: number;
  id: number;
  minRemainingSecond: number;
  optionOdds?: OptionOdds;
}

export interface StatisticsParams {
  count: number;
  configId: number;
}

export interface StatisticsResultItem {
  bigCount: number;
  evenCount: number;
  fiveCount: number;
  fourCount: number;
  oddCount: number;
  oneCount: number;
  sixCount: number;
  smallCount: number;
  threeCount: number;
  twoCount: number;
}

export interface PayParams {
  issueNo: string;
  selectTypeSet: string[];
  baseAmount: number;
  totalAmount: number;
}

export function getDiceBetting(id: number) {
  return http.get<DiceParams, DiceConfigInfo>('app/diceThree/configInfo', {
    params: {
      configId: id,
    },
  });
}

export function getDiceCurrentResult(id: number) {
  return http.get<DiceParams, DiceCurrentResultItem>('app/diceThree/current', {
    params: {
      configId: id,
    },
  });
}

//resulthistory页面接口

export function getDiceResult(id: number) {
  return http.get<DiceParams, DiceResult>('app/diceThree/latest', {
    params: {
      configId: id,
    },
  });
}
export function getDiceHomeList() {
  return http.post<null, DiceHomeItem[]>('app/diceThree/homeList');
}

export function pageDiceResult(params: DiceParams) {
  return http.post<DiceParams, DiceResult>('app/diceThree/pageLatest', params);
}

export interface WinnerResponse {
  bonus: number;
  userName: string;
  userAvatar: string;
  userPhone: string;
}

export interface OrderRes {
  totalPages: number;
  totalSize: number;
  content: OrderResContentItem[];
}

export interface OrderResContentItem {
  bigOrSmall: number;
  createTime: number;
  gameIconUrl: string;
  gameName: string;
  issNo: string;
  oddOrEven: number;
  openStatus: number;
  openTime: number;
  result: string;
  shareAward: number;
  // "shareGameDto": {
  //     "gameCode": "dice",
  //     "issNo": "20240129030420",
  //     "packageId": 2,
  //     "userId": 184954,
  //     "yearMonth": 202401
  // },
  shareLink: string;
  totalAmount: number;
  totalAwardAmount: number;
  totalCount: number;
  typeList: TypeListItem[];
}

export interface TypeListItem {
  amount: number;
  awardAmount: number;
  basePrice: number;
  createTime: number;
  number: string;
  playType: string;
  status: number;
  type: string;
}

export interface NormalType {
  orderStatus: string | '0' | '1' | '2' | '3';
  pageNo: number;
  pageSize: number;
  yearMonth?: string;
}

export interface DiceOrderData {
  amount: number;
  awardAmount: number;
  // basePrice: number;
  configId: number;
  createTime: number;
  cycle: number;
  id: number;
  issueNo: string;
  orderNo: string;
  status: number;
  userId: number;
  // 下注类型，有非常多，下划线分割，左侧是骰子类型，右侧是骰子值
  selectType: string;
  // 下注结果
  result: string;
  createDate: number;
  num: number;
  packageId: number;
  updateTime: number;
}

export const getWinners = () =>
  http.post<null, WinnerResponse[]>('app/diceThree/marquee', {group: 1});

export const getStatisticsResult = (countNum: number, id: number) => {
  return http.get<StatisticsParams, StatisticsResultItem>(
    'app/diceThree/statistics',
    {
      params: {
        count: countNum,
        configId: id,
      },
    },
  );
};

export function dicePay(issueNo: string, code: string, amount: number) {
  return http.post<PayParams, null>('app/diceThree/create', {
    issueNo,
    selectTypeSet: [code],
    baseAmount: amount,
    totalAmount: amount,
  });
}

export function dicePayment(data: PayParams) {
  return http.post<PayParams, null>('app/diceThree/create', data);
}

export function batchDicePayment(issueNo: string, userId: number) {
  return http.post<
    {
      issueNo: string;
      followBetUserId: number;
    },
    null
  >('app/diceThree/followBet', {
    issueNo,
    followBetUserId: userId,
  });
}
export function diceForecast(data: any) {
  return http.post<null, null>('app/diceThree/batchCreate', data);
}
export interface LatestStatisticsParams {
  configId: number;
}

export function getLatestOneStatisticsResult(id: number) {
  return http.get<LatestStatisticsParams, DiceResult>(
    'app/diceThree/latest/one',
    {
      params: {
        configId: id,
      },
    },
  );
}

export const getLatest = (configId: number) => {
  return http.get<{configId: number}, DiceOrderData>(
    'app/diceThree/lastTimeOrder',
    {
      params: {configId},
    },
  );
};

export const getDiceOrderList = (data: NormalType) => {
  const yearMonth = dayjs().format('YYYYMM');
  return http.post<null, OrderRes>('app/diceThree/orderList', {
    ...data,
    yearMonth,
  });
};
