import {Swiper, SwiperRef, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import React, {useCallback} from 'react';
import {Image} from 'antd-mobile';
import {BannerListItem} from '../home.type';
import {getBannerList} from '../home.service';
import {goToUrl} from '@/common-pages/game-navigate';
import styles from './home.module.scss';
import {useFocusEffect} from '@react-navigation/native';

export interface HomeBannerProps {
  version?: number;
}

const HomeBannerV2: React.FC<HomeBannerProps> = ({version}) => {
  const [bannerList, setbannerList] = React.useState<BannerListItem[]>([]);
  React.useEffect(() => {
    getBannerList(2).then(banner => {
      setbannerList(banner);
    });
  }, [version]);
  const {calcActualSize} = useScreenSize();
  const ref = React.useRef<SwiperRef>(null);
  useFocusEffect(
    useCallback(() => {
      if (ref.current) {
        ref.current.swiper.autoplay.start();
      }
      return () => {
        if (ref.current) {
          ref.current.swiper.autoplay.stop();
        }
      };
    }, []),
  );
  return bannerList.length > 0 ? (
    <Swiper
      slidesPerView={1}
      spaceBetween={calcActualSize(12)}
      autoplay={{delay: 3000}}
      loop
      ref={ref}
      pagination={{
        clickable: true,
        horizontalClass: styles['home-banner-bullet-box'],
        bulletClass: styles['home-banner-bullet'],
        bulletActiveClass: styles['home-banner-bullet-active'],
      }}
      className={'w-full px-3'}
      modules={[Autoplay, Pagination]}>
      {bannerList.map(item => (
        <SwiperSlide
          className="h-[9.0625rem]"
          key={item.id}
          onClick={() => goToUrl(item.skipLinks, item.title)}>
          <Image
            className="size-full rounded-lg"
            fallback={<div className="size-full bg-image" />}
            src={item.bannerImg}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : null;
};

export default HomeBannerV2;
