import React from 'react';
import {Image} from 'antd-mobile';
import {ReactComponent as CorncobIcon} from '@/assets/icons/sports/corncob.svg';
import {ReactComponent as PointIcon} from '@/assets/icons/sports/point.svg';
import HotItem from './hot-item';
export type SportsType =
  | 'Cricket'
  | 'Football'
  | 'Basketball'
  | 'Volleyball'
  | 'CyberSport'
  | 'League'
  | 'Matches'
  | 'Tennis'
  | 'Australian-Football'
  | 'Badminton'
  | 'Boxing'
  | 'MMA'
  | 'UFC'
  | 'Kabaddi';

export interface VsItem {
  teamName: string;
  teamPic: string;
  cricketIconType?: 'corncob' | 'point';
  score?: string;
  point?: string;
}

export interface SportsItem {
  title: string;
  hasLiveMatch?: boolean;
  vs: VsItem[];
  // INNINGS 1
  vsType?: string;
  leagueTime?: string;
  // 左右比分相关
  vsRedScore?: string;
  vsScore?: string;
  uri?: string;

  // Market列表
  marketList?: {
    price: string;
    outcomeName: string;
  }[];
}

export interface SportsCategory {
  title: string;
  icon: string;
  type: SportsType;
  allLive?: boolean;
  list: SportsItem[];
}

export interface SportSwiperItemProps {
  category: Omit<SportsCategory, 'list'>;
  item: SportsItem;
  isLive?: boolean;
}

interface HotLeagueTeamItemProps {
  teamName: string;
  teamPic?: string;
}

const HotLeagueTeamItem = ({teamName, teamPic}: HotLeagueTeamItemProps) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <div className="flex-1 flex flex-row gap-1 overflow-hidden">
        <Image
          src={teamPic}
          fallback={<div className="size-5 bg-red-300" />}
          className="size-5"
        />
        <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
          {teamName}
        </span>
      </div>
    </div>
  );
};

const MiddleContent = ({
  category,
  item,
  isLive,
}: {
  isLive?: boolean;
  category: Omit<SportsCategory, 'list'>;
  item: SportsItem;
}) => {
  return isLive ? (
    <div className="flex flex-row w-full gap-1 items-center">
      <div className="w-[5.125rem] flex flex-col items-center gap-1">
        <Image
          src={item.vs[0].teamPic}
          fallback={
            category.icon ? (
              <Image src={category.icon} className="size-10" />
            ) : undefined
          }
          className="size-10"
        />
        <span className="w-full text-t1 text-center overflow-hidden text-ellipsis whitespace-nowrap">
          {item.vs[0].teamName}
        </span>
      </div>
      <div className="flex-1 flex flex-col gap-1 items-center">
        <span className="text-[var(--sports--1)] text-[.625rem] leading-3 uppercase">
          {item.vsType}
        </span>
        <div className="text-t1 text-lg font-bold flex flex-row gap-1 items-center">
          {item.vs[0].cricketIconType ? (
            item.vs[0].cricketIconType === 'corncob' ? (
              <CorncobIcon className="size-3 text-t3" />
            ) : (
              <PointIcon className="size-3 text-t3" />
            )
          ) : null}
          <span className="leading-[1.375rem]">{item.vsScore}</span>
          {item.vs[1].cricketIconType ? (
            item.vs[1].cricketIconType === 'corncob' ? (
              <CorncobIcon className="size-3 text-t3" />
            ) : (
              <PointIcon className="size-3 text-t3" />
            )
          ) : null}
        </div>
        <span className="text-[var(--sports--1)] text-[.625rem] leading-3">
          {item.vsRedScore}
        </span>
      </div>
      <div className="w-[5.125rem] flex flex-col items-center gap-1">
        <Image
          src={item.vs[1].teamPic}
          fallback={
            category.icon ? (
              <Image src={category.icon} className="size-10" />
            ) : undefined
          }
          className="size-10"
        />
        <span className="w-full text-t1 text-center overflow-hidden text-ellipsis whitespace-nowrap">
          {item.vs[1].teamName}
        </span>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-1">
      <span className="text-t3 text-[.625rem] leading-3">
        {item.leagueTime}
      </span>
      {item.vs.map((_item, _index) => (
        <HotLeagueTeamItem
          teamPic={_item.teamPic}
          teamName={_item.teamName}
          key={_index}
        />
      ))}
    </div>
  );
};

const SportSwiperItem = ({category, item, isLive}: SportSwiperItemProps) => {
  return (
    <HotItem
      title={item.title}
      uri={item.uri}
      className="bg-white"
      middleContent={
        <MiddleContent category={category} item={item} isLive={isLive} />
      }
      marketList={item.marketList}
    />
  );
};

export default SportSwiperItem;
