import theme from '@style';
import Text from '@basicComponents/text';
import React from 'react';
import {Animated, View} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {
  goBack,
  goTo,
  navigationRef,
  replace,
  useResponsiveDimensions,
} from '@/utils';
import LinearGradient from '@basicComponents/linear-gradient';
import Button from '@basicComponents/button';
import globalStore from '@/services/global.state';
import {passwordLogin, userLogin} from './login.service';
import {styles} from './login.style';
import CodeInput from './components/code-input';
import PhoneInput from './components/phone-input';
import AccountTip from './components/account-tip';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {setScratchAuth} from '@/services/global.service';
import {useTranslation} from 'react-i18next';
import baseVariable from '@/style/base.variable';
// import {toChat} from '../game-navigate';
import {postUserInfo} from '@/services/global.service';
import {postReport, TReportType, TSourceType} from '@/services/global.service';

import {clearAllWin} from '@/components/utils/gameWin';
import {apiSendPhoneCode, defaultPhoneCode, homePage} from '@/config';
import Privacy from '@components/business/privacy/privacy';
import {setUniqueId} from '@/utils/moengage';
import AccountWrap from '@/components/business/account/account-wrap';
// const icon = require('../../assets/icons/login/login-botttom.webp');
const Login = (props: NavigatorScreenProps) => {
  const {i18n} = useTranslation();
  /** 确认来源页，有些页面需要调用方法，如chat */
  const fromPage =
    ((props.route.params as BasicObject)?.fromPage as string) || null;
  /** 直接返回的目标页面,避免原页面加载就需要token */
  const backPage =
    ((props.route.params as BasicObject)?.backPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作,这个是用来传递给注册页面的,登录页面成功直接返回  */
  const sucessPage =
    ((props.route.params as BasicObject)?.sucessPage as string) || null;
  /** 登录成功的目标页面,回到原页面操作 */
  const sucessPageParams =
    ((props.route.params as BasicObject)?.sucessPageParams as BasicObject) ||
    null;
  const [switchIndex, setSwitchIndex] = React.useState(0);
  const switchBgLeftValue = React.useRef(new Animated.Value(0)).current;
  const [userPhone, setUserPhone] = React.useState('');
  const [userPhoneCode, setUserPhoneCode] = React.useState(defaultPhoneCode);
  const [OTPCode, setOTPCode] = React.useState('');
  const [userPassword, setUserPassword] = React.useState('');

  const setValueOrCode = (value: string) => {
    switchIndex === 0 ? setOTPCode(value) : setUserPassword(value);
  };
  React.useEffect(() => {
    globalStore.removeItem('scratchToken');
    globalStore.removeItem('scratchUrl');
    globalStore.token = null;
    globalStore.userInfo = null;
  }, []);
  const forgotBoxAnimation = React.useRef(new Animated.Value(0)).current;
  const forgotBoxOpcity = forgotBoxAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });
  const forgotTrans = forgotBoxAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [(theme.paddingSize.m + 18) * -1, 0],
  });
  const {width} = useResponsiveDimensions();
  React.useEffect(() => {
    setUserPassword('');
    setOTPCode('');

    requestAnimationFrame(() => {
      Animated.timing(switchBgLeftValue, {
        toValue: (switchIndex * (width - 4 * theme.paddingSize.l)) / 2,
        duration: 300,
        useNativeDriver: true,
      }).start();
      // TODO 忘记密码
      // Animated.timing(forgotBoxAnimation, {
      //   toValue: switchIndex,
      //   duration: 300,
      //   useNativeDriver: true,
      // }).start();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchIndex, width]);

  return (
    <AccountWrap
      isClose={true}
      title={i18n.t('login.label.login')}
      onBackClick={() => {
        if (
          globalStore.isWeb &&
          (!navigationRef.current ||
            navigationRef.current.getState().routes.length < 2)
        ) {
          // 如果web进来,只有一层路由,直接到首页
          goTo(homePage, {screen: 'Home'});
          return;
        }
        if (backPage) {
          if (backPage === homePage) {
            replace(backPage, {screen: 'Home'});
          } else {
            goTo(backPage);
          }
        } else {
          goBack();
        }
      }}>
      <View
        style={[
          {
            padding: theme.paddingSize.l,
          },
        ]}>
        <View style={[theme.flex.row, theme.position.rel, theme.margin.btmm]}>
          <Animated.View
            style={[
              styles.switchBgStyle,
              {
                transform: [
                  {
                    translateX: switchBgLeftValue,
                  },
                ],
              },
            ]}>
            <LinearGradient
              style={[theme.fill.fill]}
              start={{x: 1, y: 1}}
              end={{x: 1, y: 0}}
              colors={baseVariable.basicColor.loginLinearGradient}
            />
          </Animated.View>
          {['login.label.otp-login', 'login.label.password-login'].map(
            (v, i) => (
              <NativeTouchableOpacity
                key={i}
                onPress={() => setSwitchIndex(i)}
                style={[
                  theme.flex.flex1,
                  theme.flex.center,
                  theme.padding.tbxxl,
                  theme.padding.lrl,
                ]}>
                <Text style={[theme.font.fm, theme.font.bold, theme.font.main]}>
                  {i18n.t(v)}
                </Text>
              </NativeTouchableOpacity>
            ),
          )}
        </View>
        <PhoneInput
          userPhone={userPhone}
          setUserPhone={setUserPhone}
          userPhoneCode={userPhoneCode}
          setUserPhoneCode={setUserPhoneCode}
        />
        <View style={styles.interval} />
        <CodeInput
          setValueOrCode={setValueOrCode}
          switchIndex={switchIndex ? 0 : 1}
          userPhone={userPhone}
          userPhoneCode={userPhoneCode}
          OTPCode={OTPCode}
          userPassword={userPassword}
        />
        <Animated.View
          style={[
            {
              opacity: forgotBoxOpcity,
              transform: [{scaleY: forgotBoxOpcity}],
            },
            theme.overflow.hidden,
          ]}>
          <NativeTouchableOpacity
            style={[theme.margin.topm, theme.padding.topm]}>
            <Text
              size="medium"
              textAlign="center"
              color={theme.basicColor.primary}>
              {i18n.t('login.tip.forgot')}
            </Text>
          </NativeTouchableOpacity>
        </Animated.View>
        <Animated.View
          style={[
            {
              transform: [{translateY: forgotTrans}],
            },
          ]}>
          <View
            style={{
              marginVertical: theme.paddingSize.m * 2,
            }}>
            <Button
              buttonStyle={[styles.loginButton, theme.overflow.hidden]}
              onPress={() => {
                if (userPhone === '') {
                  globalStore.globalWaringTotal(i18n.t('login.tip.no-phone'));
                  return;
                }
                if (switchIndex === 0 && OTPCode === '') {
                  globalStore.globalWaringTotal(i18n.t('login.tip.no-otp'));
                  return;
                }
                if (switchIndex === 1 && userPassword === '') {
                  globalStore.globalWaringTotal(
                    i18n.t('login.tip.no-password'),
                  );
                  return;
                }
                globalStore.globalLoading.next(true);
                (switchIndex === 0
                  ? userLogin(
                      (apiSendPhoneCode ? userPhoneCode : '') + userPhone,
                      OTPCode,
                    )
                  : passwordLogin(
                      (apiSendPhoneCode ? userPhoneCode : '') + userPhone,
                      userPassword,
                    )
                )
                  .then(async res => {
                    if (
                      switchIndex === 0 &&
                      (res as {token: string; isNewUser: boolean}).isNewUser
                    ) {
                      postReport({
                        reportType: TReportType.REGISTER,
                        sourceType:
                          localStorage.getItem('from') === 'im'
                            ? TSourceType.IM
                            : TSourceType.CHANNEL,
                      }).then(_ => localStorage.removeItem('from'));
                    }
                    if (typeof res === 'string') {
                      globalStore.token = res;
                    } else {
                      globalStore.token = res.token;
                    }
                    localStorage.removeItem('imCount');
                    globalStore.chatNumSubject.next(0);
                    const userInfo = await postUserInfo();
                    globalStore.userInfo = userInfo;
                    setScratchAuth(
                      () => {},
                      () => {
                        if (sucessPage) {
                          replace(sucessPage, sucessPageParams);
                        } else {
                          if (fromPage === 'chat') {
                            // toChat();
                            goTo('Chat');
                          } else if (
                            !navigationRef.current ||
                            navigationRef.current.getState().routes.length < 2
                          ) {
                            goTo(homePage, {screen: 'Home'});
                          } else {
                            goBack();
                          }
                        }
                        globalStore.globalLoading.next(false);
                      },
                    );
                    clearAllWin().then();
                    const {userId, packageId} = userInfo;
                    setUniqueId(`${userId}_${packageId}`);
                  })
                  .catch(() => {
                    globalStore.globalLoading.next(false);
                  });
              }}
              disabled={
                switchIndex === 1
                  ? userPassword.length < 6
                  : OTPCode.length !== 6
              }
              type="linear-primary"
              color={theme.basicColor.white}
              titleBold
              title={i18n.t('login.label.login')}
            />
          </View>
          <AccountTip
            tip="login.tip.new-account"
            linkTip="login.tip.sing-in"
            onPressLink={() => {
              const data: BasicObject = {};
              if (backPage) {
                data.backPage = backPage;
              }
              if (sucessPage) {
                data.sucessPage = sucessPage;
              }
              if (sucessPageParams) {
                data.sucessPageParams = sucessPageParams;
              }
              goTo('SingUp', data);
            }}
          />
        </Animated.View>
        <View
          style={[
            {
              marginTop: -theme.paddingSize.l * 2,
            },
          ]}>
          <Privacy />
        </View>
      </View>
    </AccountWrap>
  );
};

export default Login;
