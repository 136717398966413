import React from 'react';
// import {ReactComponent as StarIcon} from '@/assets/icons/sports/star.svg';
import {useTranslation} from 'react-i18next';
import globalStore from '@/services/global.state';

export interface HotItemProps {
  title: string;
  headerRightContent?: React.ReactNode;
  className?: string;
  middleContent: React.ReactNode;
  marketList?: {price: string; outcomeName: string}[];
  uri?: string;
}

const HotItem = ({
  title,
  className,
  headerRightContent,
  middleContent,
  marketList,
  uri = '',
}: HotItemProps) => {
  const {t} = useTranslation();
  return (
    <div
      className={`${className || ''} rounded-lg`}
      onClick={() => {
        globalStore.openSports.next(uri);
      }}>
      <div className="h-8 flex flex-row gap-3 px-2 items-center border-b border-b-[rgb(232, 234, 238)]/50">
        <span className="text-t2 text-xs flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
          {title}
        </span>
        <div className="flex flex-row gap-2 items-center">
          {headerRightContent}
          {/* <StarIcon className="size-4 text-t3" /> */}
        </div>
      </div>
      <div className="flex flex-col p-2 gap-2">
        {middleContent}
        <div className="flex flex-row gap-2">
          {marketList && marketList.length <= 3 ? (
            marketList.map((item, index) => (
              <div
                className="flex flex-col flex-1 h-14 justify-center items-center gap-1 bg-[#F5F4F1] rounded"
                key={index}>
                <span className="text-[#c625ac] text-base">{item.price}</span>
                <span className="text-t3 text-xs">{item.outcomeName}</span>
              </div>
            ))
          ) : (
            <div className="flex flex-col flex-1 h-14 justify-center items-center gap-1 bg-[#F5F4F1] rounded">
              <span className="text-[#c625ac] text-base">
                {t('home.label.availOutcomes', {
                  count: marketList?.length || 0,
                })}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotItem;
