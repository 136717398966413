import {envConfig} from '@/utils';
import {SportsCategory, Event, SportsListItem, PeriodScore} from './service';
import {
  SportsItem,
  SportsType,
  SportSwiperItemProps,
} from './components/swiper-item';
import dayjs from 'dayjs';

export function getSportsCategories(
  sportsName: string,
  category: SportsCategory,
  count: number = 1,
): SportsItem[] {
  const categoryName = category.Name.en;
  const list: SportsItem[] = [];
  for (let i = 0; i < category.Tournaments.length; i++) {
    const tour = category.Tournaments[i];
    const tourName = tour.Name.en;
    for (let j = 0; j < tour.Events.length; j++) {
      const event = tour.Events[j];
      const versus = event.Competitors.map(item => ({
        teamName: item.Name.en,
        teamPic: `${envConfig.gr8IframeUrl}taxonomyicons/competitors/${item.Id}-164w`,
      }));
      const matchInfo = getMatchInfo(sportsName, event);
      list.push({
        title: `${tourName}. ${categoryName}. ${sportsName}`,
        // 必然是直播赛事，所以这边不展示这些小部件
        hasLiveMatch: false,
        vs: versus,
        ...matchInfo,
      });
    }
  }
  return list.slice(0, count);
}

const allSportFullPeriodMap: Record<string, number> = {
  Basketball: 8,
  Football: 4,
  Volleyball: 1,
  CyberSport: 1,
  Cricket: 1,
};

function getSlug(url: string) {
  const urlArr = url.split('/');
  for (let i = 0; i < urlArr.length; i++) {
    if (urlArr[i] === 'events') {
      return urlArr[i + 1];
    }
  }
  return '';
}

function getLeagueTime(startTime: string) {
  const leagueDay = dayjs(startTime).diff(dayjs(), 'day');
  let leagueTime = '';
  if (leagueDay < 1) {
    leagueTime = 'Today, ' + dayjs(startTime).format('HH:mm');
  } else if (leagueDay < 2) {
    leagueTime = 'Tomorrow, ' + dayjs(startTime).format('HH:mm');
  } else {
    leagueTime = dayjs(startTime).format('MMMM D, HH:mm');
  }
  return leagueTime;
}

function getCricketSubScore(matchPeriodAll?: PeriodScore[]) {
  if (!matchPeriodAll || !matchPeriodAll.length) {
    return;
  }
  const runningScore = matchPeriodAll.find(
    item => item.Type === 'Runs' && item.SubPeriod == null,
  );
  const wicketScore = matchPeriodAll.find(item => item.Type === 'Wickets');
  const runningArr = runningScore?.Score.split('-');
  const wicketArr = wicketScore ? wicketScore.Score.split('-') : null;
  return `${runningArr?.[0] || '0'}${
    wicketArr ? `/${wicketArr[0] || '0'}` : ''
  }:${runningArr?.[1] || '0'}${wicketArr ? `/${wicketArr[1] || '0'}` : ''}`;
}

function getMatchInfo(
  sportsName: string,
  event: Event,
): Omit<SportsItem, 'title' | 'vs'> {
  const market = event.Markets[0];
  const periodName = market.PeriodName.en;
  const matchingPeriodId = market.MarketKey.Period;
  const fullPeriodId = allSportFullPeriodMap[sportsName] || 1;
  const periods = event.PeriodScores;
  const urlFirst = Object.values(event.URL)[0];
  const slug = getSlug(urlFirst);

  const fullPeriod = periods.find(item => item.Period === fullPeriodId);
  const mainScore = fullPeriod?.Score.split('-').join(':') || '0:0';
  const marketList = market.Outcomes.map((item, index, arr) => ({
    price: item.Price + '',
    outcomeName:
      arr.length === 2
        ? index + 1 + ''
        : arr.length === 3
        ? ['1', 'x', '2'][index]
        : item.OutcomeName.en,
  }));
  let leagueTime = getLeagueTime(event.StartTime);
  if (sportsName === 'Cricket') {
    const matchPeriod5All = periods.filter(item => item.Period === 5);
    const matchPeriod6All = periods.filter(item => item.Period === 6);
    let vsSub1Score: string | undefined;
    if (matchPeriod6All.length) {
      vsSub1Score = getCricketSubScore(matchPeriod6All);
    } else if (matchPeriod5All.length) {
      vsSub1Score = getCricketSubScore(matchPeriod5All);
    }

    return {
      vsType: matchPeriod6All.length ? 'Innings 2' : 'Innings 1',
      vsScore: mainScore,
      vsRedScore: vsSub1Score,
      leagueTime: leagueTime,
      uri: `/events/${slug}`,
      marketList: marketList,
    };
  }
  const matchPeriod = periods.find(item => item.Period === matchingPeriodId);

  return {
    vsType: periodName,
    vsScore: mainScore,
    vsRedScore:
      matchingPeriodId !== fullPeriodId &&
      matchPeriod &&
      fullPeriod &&
      matchPeriod?.Score !== fullPeriod?.Score
        ? matchPeriod?.Score.split('-').join(':')
        : undefined,
    leagueTime: leagueTime,
    uri: `/events/${slug}`,
    marketList,
  };
}

const sportsIconMap: Record<string, string> = {
  Basketball: require('@/assets/icons/sports/basketball.webp'),
  Football: require('@/assets/icons/sports/football.webp'),
  Volleyball: require('@/assets/icons/sports/volleyball.webp'),
  CyberSport: require('@/assets/icons/sports/cybersport.webp'),
  Cricket: require('@/assets/icons/sports/cricket.webp'),
  Kabaddi: require('@/assets/icons/sports/kabaddi.webp'),
};

export function convertToSportsCategory(sportsListItem: SportsListItem) {
  const {Sport, Categories} = sportsListItem;
  return {
    title: Sport,
    icon: sportsIconMap[Sport],
    type: Sport as SportsType,
    list: Categories.reduce<SportsItem[]>((all, item) => {
      return [...all, ...getSportsCategories(Sport, item, 5)];
    }, []),
  };
}

const filterCategory = (name: string) => {
  return (
    !name.toLowerCase().includes('virtual') &&
    name.toLowerCase() !== 'simulated reality league' &&
    name.toLowerCase() !== 'ecricket'
  );
};

export function convertToSportsSwiperItemList(
  sportsListItemArr: SportsListItem[] = [],
) {
  let _sportsListItemArr = Array.from(sportsListItemArr);
  let indexList = Array.from({length: _sportsListItemArr.length}, () => 0);
  let itemList: SportSwiperItemProps[] = [];
  while (true) {
    const hasNext = indexList.length > 0;
    if (!hasNext) {
      return itemList;
    }
    const checkIndex = Math.floor(Math.random() * indexList.length);
    const sportsListItem = _sportsListItemArr[checkIndex];
    const {Sport, Categories} = sportsListItem;
    const item = Categories[indexList[checkIndex]];
    if (filterCategory(item.Name.en)) {
      const sportsItemList = getSportsCategories(Sport, item, 5);
      sportsItemList.forEach(_item => {
        itemList.push({
          category: {
            title: Sport,
            type: Sport as SportsType,
            icon: sportsIconMap[Sport],
          },
          item: _item,
        });
      });
    }

    indexList[checkIndex]++;
    if (indexList[checkIndex] >= Categories.length) {
      indexList.splice(checkIndex, 1);
      _sportsListItemArr.splice(checkIndex, 1);
    }
  }
}
