import theme from '@/style';
import LazyImage from '@/components/basic/image';
import {goTo, toAgentApply} from '@/utils';
import React, {useState, useCallback} from 'react';
import {View, Image} from 'react-native';
import {useInnerStyle} from '../home.hooks';
import {useTranslation} from 'react-i18next';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import globalStore from '@/services/global.state';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';
import {homePage} from '@/config';
import {useFocusEffect} from '@react-navigation/native';

const HomeGoldArea: React.FC = () => {
  const {i18n} = useTranslation();
  const {
    size: {screenWidth},
  } = useInnerStyle();
  const list = [
    {
      bgImgUrl: require('@/assets/imgs/home/gold-promotions.png'),
      moduleName: i18n.t('home.label.promotions'),
      jumpHandler() {
        goTo('PromotionsV2', {tabKey: 'promotion'});
      },
    },
    {
      bgImgUrl: require('@/assets/imgs/home/gold-invite.png'),
      moduleName: i18n.t('home.label.invite'),
      jumpHandler() {
        goTo('Invitation');
      },
    },
    {
      bgImgUrl: require('@/assets/imgs/home/gold-agency.png'),
      moduleName: i18n.t('home.label.agency'),
      jumpHandler() {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user.isAgent === 1) {
          goTo('ProxyHome');
        } else {
          toAgentApply();
        }
      },
    },
    {
      bgImgUrl: require('@/assets/imgs/home/gold-lucky-spin.png'),
      moduleName: i18n.t('home.label.luckySpin'),
      jumpHandler() {
        if (!token) {
          goTo('Login');
          return;
        }
        spinShow();
      },
    },
    {
      bgImgUrl: require('@/assets/imgs/home/gold-chat.png'),
      moduleName: i18n.t('home.label.chat'),
      jumpHandler: async () => {
        // if (!token) {
        //   goTo('Login', {
        //     backPage: homePage,
        //     // sucessPage: homePage,
        //     // sucessPageParams: {
        //     //   screen: 'Chat',
        //     // },
        //     sucessPage: 'Chat',
        //   });
        //   return;
        // }
        // let customers: RechargeCustomerItem[] = [];
        // try {
        //   customers = await getRechargeCustomers();
        // } catch (error) {
        //   console.log(error);
        // }
        // const rechargeCustomers = customers.filter(
        //   // eslint-disable-next-line eqeqeq
        //   c => c.imPermanent == '1' && c.imUserStatus == '1',
        // );

        // if (!token) {
        //   goTo('Login', {
        //     backPage: homePage,
        //     sucessPage: homePage,
        //     sucessPageParams: {
        //       screen: 'Chat',
        //     },
        //   });
        //   return;
        // }

        goTo(homePage, {
          screen: 'Chat',
          params: {
            // customerId:
            //   rechargeCustomers.length > 0
            //     ? rechargeCustomers[
            //         Math.floor(Math.random() * rechargeCustomers.length)
            //       ].imUserId
            //     : '',
            ts: Date.now(),
          },
        });
      },
    },
  ];
  const DISIGN_WEIDTH = 375;
  const computedSize = (num: number) => (num * screenWidth) / DISIGN_WEIDTH;
  const size = {
    computedL: computedSize(theme.paddingSize.l),
    computedS: computedSize(theme.paddingSize.s),
    computed60: computedSize(60),
    computed44: computedSize(44),
    computed40: computedSize(40),
    computed36: computedSize(36),
  };
  const [drawImage, setDrawImage] = useState<string>();
  const [spinBasePrice, setSpinBasePrice] = useState(10);
  const [spinBatchCount, setSpinBatchCount] = useState(30);
  const [freeCount, setFreeCount] = useState<number>(0);
  const [token, setToken] = useState('');

  const onRefreshSpinConfig = () => {
    if (globalStore.token) {
      postSpinConfig(true).then(data => {
        setFreeCount(data?.myFree || 0);
      });
    }
  };
  const doNotice = () => {
    onRefreshSpinConfig();
  };

  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    drawImage,
    onNotice: doNotice,
    batchCount: spinBatchCount,
    singleAmount: spinBasePrice,
    freeCount: freeCount,
  });
  useFocusEffect(
    useCallback(() => {
      const tokenSub = globalStore.tokenSubject.subscribe(_token => {
        setToken(_token || '');
        postSpinConfig(!!_token)
          .then(data => {
            setDrawImage(data?.image);
            setSpinBasePrice(data?.singleAmount);
            setSpinBatchCount(data?.batchCount);
            setFreeCount(data?.myFree || 0);
            Image.prefetch(data.image).catch(e => {
              console.log(e);
            });
          })
          .catch(e => {
            console.log(e);
          });
      });
      const doNoticeSub = globalStore.doNotices.subscribe(() => {
        doNotice();
      });
      return () => {
        tokenSub.unsubscribe();
        doNoticeSub.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.flex.centerByCol,
        {
          margin: size.computedL,
        },
      ]}>
      {list.map((v, i) => {
        return (
          <div
            key={i}
            className="flex flex-col justify-center gap-1 h-[4.875rem] relative"
            onClick={v.jumpHandler}>
            {v.moduleName === i18n.t('home.label.luckySpin') &&
              !!freeCount &&
              freeCount > 0 && (
                <div
                  className="flex items-center justify-center absolute w-5 h-5 top-0 right-0 z-10 px-[0.3125rem] py-0.5 rounded-full bg-[#DC083B]"
                  style={{
                    border: '1px solid #fff',
                  }}>
                  <span className="text-[0.625rem] font-din font-bold text-[#fff]">
                    !
                  </span>
                </div>
              )}
            <LazyImage
              imageUrl={v.bgImgUrl}
              width={size.computed60}
              height={size.computed60}
              occupancy="transparent"
            />
            <div className="text-t1 text-center text-xs capitalize">
              {v.moduleName}
            </div>
          </div>
        );
      })}
      {renderSpin}
    </View>
  );
};

export default HomeGoldArea;
