import React, {useMemo} from 'react';

interface HomeBottomCardProps {
  imgs: string[];
  onClick?: () => void;
  renderItem: (url: string, index: number) => React.ReactNode;
  title: string;
  cols?: number;
  gap?: string;
  px?: string;
}

const HomeBottomCard = ({
  imgs,
  title,
  renderItem,
  onClick = () => {
    console.log('cilck it!');
  },
  cols,
  gap,
  px = 'px-0',
}: HomeBottomCardProps) => {
  const list = useMemo(() => {
    if (!cols) {
      return imgs;
    }
    return imgs.reduce<string[][]>((res, cur, index) => {
      const row = Math.floor(index / cols);
      if (index % cols === 0) {
        res[row] = [cur];
      } else {
        res[row].push(cur);
      }
      return res;
    }, []);
  }, [imgs, cols]);
  return (
    <div className="w-full px-3 py-[0.625rem] flex flex-col gap-[0.625rem] bg-[#EFEFEF]">
      <span className="text-t1 text-sm font-bold">{title}</span>
      {!!gap && (
        <div className={`flex flex-row ${gap} ${px}`}>
          {(list as string[]).map((url, index) => (
            <div key={index} onClick={onClick}>
              {renderItem(url, index)}
            </div>
          ))}
        </div>
      )}
      {!gap && (
        <div className="flex flex-col gap-[0.625rem]">
          {(list as string[][]).map((item, index) => (
            <div
              key={index}
              className={`flex flex-row items-center justify-between ${px}`}>
              {item.map((url, i) => (
                <div key={i} onClick={onClick}>
                  {renderItem(url, i)}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HomeBottomCard;
