import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ActivityDetailResponse} from '../type';
// import globalStore from '@/services/global.state';
import {Button, Image} from 'antd-mobile';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack, replace, replaceByUri} from '@/utils';
import {useTranslation} from 'react-i18next';
import BackgroundTimer from '@components/utils/timer';
import styles from './common.module.scss';
import {useLogin} from '@/utils/state-hooks';
import dayjs from 'dayjs';

interface CommonPageTemplateData {
  backgroundPic?: string;
  backgroundColor?: string;
  eventRule?: string;
  redirectUrl?: string;
  buttonLinear?: string;
}

export const CountDown = ({
  endTime,
  onEnd,
}: {
  endTime?: string;
  onEnd?: () => void;
}) => {
  const timer = useRef<number>();

  const [timeVersion, setTimeVersion] = useState(0);
  const timeEnd = useMemo(() => {
    if (!endTime) {
      return 0;
    }
    return +endTime;
  }, [endTime]);

  const timeRemain = useMemo(() => {
    const _remain = timeEnd - Date.now();
    if (_remain < 0) {
      return [0, 0, 0, 0];
    }
    let s = Math.ceil(_remain / 1000);
    let m = Math.floor(s / 60);
    s = s % 60;
    let h = Math.floor(m / 60);
    m = m % 60;
    let d = Math.floor(h / 24);
    h = h % 24;
    return [d, h, m, s];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEnd, timeVersion]);

  const [days, hours, minutes, seconds] = timeRemain;

  useEffect(() => {
    timer.current = BackgroundTimer.setInterval(() => {
      setTimeVersion(v => v + 1);
    }, 1000);
    return () => {
      timer.current && BackgroundTimer.clearInterval(timer.current);
    };
  }, [endTime]);

  useEffect(() => {
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      if (timer.current) {
        BackgroundTimer.clearInterval(timer.current);
      }
      onEnd?.();
    }
  }, [days, hours, minutes, seconds, onEnd]);

  return (
    <div className="flex flex-row items-center text-t2 text-xs font-bold gap-2">
      {days > 0 && (
        <span className="text-white text-sm font-bold">{days} Day</span>
      )}
      <div className="flex flex-row items-center gap-0.5">
        <span
          className={`size-5 flex items-center justify-center text-center bg-black text-white rounded-sm ${styles['countdown-item']}`}>
          {hours.toString().padStart(2, '0')}
        </span>
        <span>:</span>
        <span
          className={`size-5 flex items-center justify-center text-center bg-black text-white rounded-sm ${styles['countdown-item']}`}>
          {minutes.toString().padStart(2, '0')}
        </span>
        <span>:</span>
        <span
          className={`size-5 flex items-center justify-center text-center bg-black text-white rounded-sm ${styles['countdown-item']}`}>
          {seconds.toString().padStart(2, '0')}
        </span>
      </div>
    </div>
  );
};

const CommonPage = () => {
  const [data, setData] = useState<ActivityDetailResponse>();

  // const lang = globalStore.lang;
  useEffect(() => {
    const _data = localStorage.getItem('promotion-detail-v2');
    if (_data) {
      try {
        setData(JSON.parse(_data));
      } catch (error) {
        console.error('parse error', error);
      }
    }
  }, []);
  const templateData = useMemo<CommonPageTemplateData>(() => {
    if (!data) {
      return {};
    }
    try {
      let json = data.templateVariableJson || '{}';
      while (typeof json === 'string') {
        json = JSON.parse(json);
      }
      return json;
    } catch (error) {
      console.info('parse templateVariableJson error');
      return {};
    }
  }, [data]);
  const {t} = useTranslation();
  const login = useLogin();
  const calcActivityTime = useMemo(() => {
    return (
      dayjs(data?.activityInfo?.startTime).format('DD/MM YYYY') +
      ' - ' +
      dayjs(data?.activityInfo?.endTime).format('DD/MM YYYY')
    );
  }, [data]);
  return (
    <div className="flex flex-col w-auto h-screen overflow-hidden relative">
      <DetailNavTitle
        onBack={goBack}
        title={t('promotionsV2.details')}
        serverRight
        hideAmount
        noShadow
      />
      <div
        className="flex flex-col flex-1 overflow-hidden relative"
        style={{
          background: templateData.backgroundColor,
        }}>
        <Image
          className="absolute top-0 left-0 w-full"
          src={templateData.backgroundPic}
          fallback={
            <div
              style={{
                background: templateData.backgroundColor,
              }}
            />
          }
        />
        <div className="flex flex-col mt-[9.15625rem] flex-1 z-[5] overflow-hidden">
          <div className="flex flex-row items-center justify-between px-5 py-2">
            <div className="flex flex-col text-white text-xs gap-1">
              <span>{t('promotionsV2.activityTime')}</span>
              <span className="font-bold">{calcActivityTime}</span>
            </div>
            <div className="flex flex-col text-white text-xs items-end gap-1">
              <span>{t('promotionsV2.remainTime')}</span>
              <CountDown
                endTime={data?.activityInfo?.endTime}
                onEnd={() => {}}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 overflow-auto">
            <div className="mx-4 bg-white rounded-xl flex flex-col p-3 gap-3">
              <div className="flex flex-row items-center gap-2">
                <div className="w-1 h-3 bg-c1" />
                <div className="text-t1 text-sm font-bold capitalize">
                  {t('promotionsV2.rules')}
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 gap-2 ${styles['event-rule']}`}
                dangerouslySetInnerHTML={{__html: templateData.eventRule || ''}}
              />
            </div>
            <div className="h-[5.75rem] flex-none" />
          </div>

          <div
            className="absolute bottom-0 left-0 right-0 pt-8 pb-3 px-5"
            style={{
              background: templateData.buttonLinear || undefined,
            }}>
            <Button
              className="rounded-full bg-c1 text-white text-base font-bold w-full h-12 flex items-center justify-center before:rounded-full uppercase"
              onClick={() => {
                if (!login) {
                  replace('Login');
                } else if (templateData.redirectUrl) {
                  replaceByUri(templateData.redirectUrl);
                }
              }}>
              {t('promotionsV2.joinNow')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonPage;
