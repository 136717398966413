import globalStore from '@/services/global.state';
import theme from '@/style';
import React, {useCallback, useMemo, useState} from 'react';
import {RefreshControl, ScrollView, StatusBar, View} from 'react-native';
import HomeHeader from './components/home-header';
import DownloadBanner from './components/home-download-banner';
import HomeBanner from './components/home-banner-v2';
import HomeGoldArea from './components/home-gold-area';
import HomeFloorGames from './components/home-floor-games';
import HomeWinningInformation from './components/home-winning-information';
import HomeSide from './components/home-side';
import {getHomeGames} from './home.service';
import {HomeGameFloorItem} from './home.type';
import {useFocusEffect} from '@react-navigation/native';
// import {useInnerStyle} from './home.hooks';
import {FadeInView} from '@/components/basic/animations';
import {useResponsiveDimensions} from '@/utils';
import HomeFloorLottery from './components/home-floor-lottery';
import HomePartners from './components/home-partners';
import HomePays from './components/home-pays';
import HomeSocial from './components/home-social';
import HomeLicenses from './components/home-licenses';
import {Image} from 'antd-mobile';
import DownImg from '@assets/imgs/home/down-img.webp';
import {useTranslation} from 'react-i18next';
import {downloadApk, stopBeforeDownload} from '@/utils';
import {getSportsList} from '../gr8-sports/service';
import {SportSwiperItemProps} from '../gr8-sports/components/swiper-item';
import {convertToSportsSwiperItemList} from '../gr8-sports/converts';
import SportSwiper from '../gr8-sports/components/sport-swiper';
import HomeFloorScratch from './components/home-floor-scratch';

const Home = () => {
  const {i18n} = useTranslation();
  const [show, setShow] = useState(false);
  const [homeGames, setHomeGames] = useState<HomeGameFloorItem[]>();
  // const {spaceAreaStyles} = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);
  const [version, setVersion] = useState(0);
  const [prematchSportsList, setPrematchSportsList] =
    useState<SportSwiperItemProps[]>();
  const [liveSportsList, setLiveSportsList] =
    useState<SportSwiperItemProps[]>();

  const allGames = useMemo(() => {
    const _games: (
      | {
          type: 'sports';
          sportsType: 'prematch' | 'live';
          item: SportSwiperItemProps[];
        }
      | {type: 'lottery'; item: HomeGameFloorItem}
    )[] = [];
    if (liveSportsList) {
      _games.push({
        type: 'sports',
        sportsType: 'live',
        item: liveSportsList,
      });
    }
    for (let i in homeGames || []) {
      if (i === '2' && prematchSportsList?.length) {
        _games.push({
          type: 'sports',
          sportsType: 'prematch',
          item: prematchSportsList,
        });
      }
      if (homeGames && homeGames[i]) {
        _games.push({
          type: 'lottery',
          item: homeGames[i],
        });
      }
    }
    if ((!homeGames || homeGames?.length <= 2) && prematchSportsList?.length) {
      _games.push({
        type: 'sports',
        sportsType: 'prematch',
        item: prematchSportsList,
      });
    }
    return _games;
  }, [prematchSportsList, liveSportsList, homeGames]);

  React.useEffect(() => {
    StatusBar.setBackgroundColor(theme.basicColor.primary);
    StatusBar.setBarStyle('dark-content');
    init();
    // 请求接口优先请求live,否则更容易在出现请求live的时候Too many requests错误
    getSportsList('live').then(sports => {
      if (sports && typeof sports !== 'string') {
        setLiveSportsList(convertToSportsSwiperItemList(sports));
      }
    });
    getSportsList().then(sports => {
      if (sports && typeof sports !== 'string') {
        setPrematchSportsList(convertToSportsSwiperItemList(sports));
      }
    });
  }, []);
  const init = (loading: boolean = true) => {
    loading && globalStore.globalLoading.next(true);
    return getHomeGames()
      .then(games => {
        setHomeGames(games);
      })
      .finally(() => globalStore.globalLoading.next(false));
  };
  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleFocusEffect);
  const handleRefresh = () => {
    setRefreshing(true);
    setVersion(_v => _v + 1);
    Promise.allSettled([init(false)]).finally(() => setRefreshing(false));
  };
  const {width: screenWidth} = useResponsiveDimensions();
  const onClickDownloadBtn = () => {
    if (stopBeforeDownload()) {
      return;
    }
    downloadApk();
  };
  // React.useEffect(() => {
  //   setShow(true);
  // }, []);
  // const [hidden, setHidden] = React.useState(false);
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setHidden(true);
  //   }, 5000);
  //   setTimeout(() => {
  //     setHidden(false);
  //   }, 10000);
  // }, []);
  return (
    <FadeInView
      style={[
        theme.fill.fill,
        {
          minWidth: screenWidth,
        },
        theme.flex.col,
      ]}>
      <HomeHeader />
      {globalStore.isWeb ? <DownloadBanner /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }>
        <div className={'bg-white'}>
          <View style={{height: theme.paddingSize.l}} />
          <HomeBanner version={version} />
          <HomeGoldArea />
          {allGames?.map((_games, index) => {
            if (_games.type === 'lottery') {
              const floor = _games.item;
              if (
                floor.uniqueKey === 'lotteryV2' &&
                floor.gamesList[3].gameUrl === '*'
              ) {
                return (
                  <HomeFloorLottery
                    key={index}
                    title={floor.name}
                    icon={floor.categoryPic}
                  />
                );
              }
              if (floor.uniqueKey === 'scratchV2') {
                return (
                  <HomeFloorScratch
                    key={index}
                    title={floor.name}
                    icon={floor.categoryPic}
                    imageUrl={floor.gamesList?.[0]?.gamePic}
                  />
                );
              }
              return floor.gamesList ? (
                <HomeFloorGames
                  type={floor.uniqueKey}
                  key={index}
                  title={floor.name}
                  icon={floor.categoryPic}
                  list={floor.gamesList || []}
                />
              ) : null;
            } else {
              const sports = _games.item;
              return (
                <SportSwiper
                  key={index}
                  list={sports}
                  isLive={_games.sportsType === 'live'}
                />
              );
            }
          })}
          <HomeWinningInformation version={version} />
        </div>
        <div className="w-full bg-white">
          <HomePartners />
          <div className="h-[0.0625rem]" />
          <HomePays />
          <div className="h-[0.0625rem]" />
          <HomeSocial />
          <div className="h-[0.0625rem]" />
          <HomeLicenses />
        </div>
        {/* <View
          style={[
            theme.fill.fillW,
            spaceAreaStyles.bottom,
            {backgroundColor: '#fff'},
          ]}
        /> */}
      </ScrollView>
      <HomeSide />
      {show && (
        <div className="fixed bottom-[5.125rem] w-[23.4375rem] m-auto flex flex-row justify-center">
          <div
            className="flex py-2 px-[0.875rem] items-center gap-2 rounded-[2.75rem] bg-[--1]"
            style={{
              boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.25)',
            }}>
            <div className="w-[1.75rem] h-[1.75rem]">
              <Image src={DownImg} className="w-[1.75rem] h-[1.75rem]" />
            </div>
            <span
              className="text-white text-sm font-bold leading-[66.857%]"
              onClick={() => {
                onClickDownloadBtn();
                setShow(false);
              }}
              style={{
                textShadow: '0px 0.78px 0.39px rgba(71, 129, 255, 0.50)',
              }}>
              {i18n.t('home.label.downText')}
            </span>
            <div className="w-[0.0625rem] h-6 bg-white opacity-50" />
            <div onClick={() => setShow(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none">
                <rect
                  x="6.39258"
                  y="15.3203"
                  width="3"
                  height="13.3333"
                  rx="1.5"
                  transform="rotate(-135 6.39258 15.3203)"
                  fill="white"
                />
                <rect
                  x="15.584"
                  y="13.1992"
                  width="3"
                  height="13.3333"
                  rx="1.5"
                  transform="rotate(135 15.584 13.1992)"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </FadeInView>
  );
};
export default Home;
