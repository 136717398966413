import React from 'react';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo} from '@/utils';
import Text from '@/components/basic/text';
import {View, Image, ImageBackground, StyleSheet} from 'react-native';
import LazyImage from '@/components/basic/image';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface HomeFloorGamesProps {
  icon: string;
  title: string;
  imageUrl: string;
}
const HomeFloorGames: React.FC<HomeFloorGamesProps> = ({
  icon,
  title,
  imageUrl,
}) => {
  const {calcActualSize, screenWidth} = useScreenSize();
  const styles = StyleSheet.create({
    titleBox: {
      height: calcActualSize(40),
    },
    smallCard: {
      height: calcActualSize(156),
      // 25%加上边距后,一行不会超过3个,但也不至于少于2个
      // 所以flex1+minwidth25%可以视为一行三个
      minWidth: '25%',
      flex: 1,
    },
    bigCard: {
      height: calcActualSize(156),
      width: '100%',
    },
  });

  const goGames = () => {
    goTo('Scratch', {
      hideInnerTitle: 1,
    });
  };

  return (
    <View>
      <ImageBackground
        style={[
          styles.titleBox,
          theme.flex.row,
          theme.flex.centerByCol,
          {
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
          },
        ]}
        source={require('@assets/imgs/home/home-floor-title-bg.webp')}>
        <NativeTouchableOpacity
          onPress={goGames}
          style={[theme.flex.row, theme.flex.centerByCol, theme.fill.fillW]}>
          {icon ? (
            <Image
              source={{uri: icon}}
              style={[
                {
                  width: calcActualSize(theme.iconSize.xxl),
                  height: calcActualSize(theme.iconSize.xxl),
                  marginRight: calcActualSize(theme.paddingSize.s),
                },
              ]}
            />
          ) : (
            <View
              style={[
                theme.background.palegrey,
                {
                  width: calcActualSize(theme.iconSize.xxl),
                  height: calcActualSize(theme.iconSize.xxl),
                  marginRight: calcActualSize(theme.paddingSize.s),
                },
              ]}
            />
          )}
          <Text calc size="medium" main blod>
            {title}
          </Text>
        </NativeTouchableOpacity>
      </ImageBackground>
      <View
        style={[
          theme.flex.wrap,
          theme.flex.row,
          {
            width: screenWidth,
            gap: calcActualSize(theme.paddingSize.s),
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
            paddingBottom: calcActualSize(theme.paddingSize.l),
          },
        ]}>
        <NativeTouchableOpacity
          onPress={goGames}
          style={[
            theme.background.palegrey,
            theme.borderRadius.m,
            theme.overflow.hidden,
            styles.bigCard,
          ]}>
          <LazyImage imageUrl={imageUrl} width={'100%'} height={'100%'} />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

export default HomeFloorGames;
