import Text from '@/components/basic/text';
import theme from '@/style';
import React, {FC, useEffect} from 'react';
import {View} from 'react-native';
import {BasicObject, NavigatorScreenProps} from '@/types';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import Home from './pages/home';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Shadow} from 'react-native-shadow-2';
import Me from './pages/me';
// import Promotion from '@/common-pages/promotion';
import Casino from './pages/casino';
import LiveCasino from './pages/live-casino';
import {toAgentApply} from './utils';
import {Image} from 'antd-mobile';
// import IMWebView from './common-pages/im/im-webview';
// import {toAgentApply} from '@/utils';
// import {toChat} from '@/common-pages/game-navigate';

const Tab = createBottomTabNavigator();

const homeIcon = require('@assets/icons/main-tab/home-new-2.webp');
const homeActiveIcon = require('@assets/icons/main-tab/home-active-new.webp');
const sportIcon = require('@assets/icons/main-tab/sport-new.webp');
const sportActiveIcon = require('@assets/icons/main-tab/sport-active.webp');
const liveIcon = require('@assets/icons/main-tab/live.webp');
const liveActiveIcon = require('@assets/icons/main-tab/live-active.webp');
const casinoIcon = require('@assets/icons/main-tab/casino-new.webp');
const casinoActiveIcon = require('@assets/icons/main-tab/casino-active.webp');
const meIcon = require('@assets/icons/main-tab/me-new.webp');
const meActiveIcon = require('@assets/icons/main-tab/me-active.webp');
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
// const chatIcon = require('@assets/icons/main-tab/chat-new.webp');
// const chatActiveIcon = require('@assets/icons/main-tab/chat-active.webp');
// import IMWebView from './common-pages/im/im-webview';
const chatIcon = require('@assets/icons/main-tab/chat-new.webp');
const chatActiveIcon = require('@assets/icons/main-tab/chat-active.webp');
import IMWebView from './common-pages/im/im-webview';
import {goTo} from './utils';
import {homePage} from './config';
import SportsContainer from './common-pages/sports/container';

const shadow = {
  startColor: '#0004',
  distance: 1,
  offset: [0, 0],
  style: [
    theme.flex.row,
    theme.flex.around,
    theme.background.white,
    theme.fill.fillW,
    {
      height: 50,
    },
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0 0 1px 0px #0004',
  });
}

const TAB_OBJ: {[key: string]: string} = {
  Home: 'HOME',
  Promotions: 'PROMOTIONS',
  Invite: 'INVITE',
  Chat: 'CHAT',
  Me: 'ME',
  ProxyHome: 'AGENT',
};

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img?: string;
  activeImg?: string;
  unmountOnBlur?: boolean;
  lazy?: boolean;
  params?: BasicObject;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: homeIcon,
    activeImg: homeActiveIcon,
  },
  // {
  //   name: 'Promotions',
  //   link: 'index/promotion',
  //   component: Promotion,
  //   img: promotionsIcon,
  //   activeImg: promotionsActiveIcon,
  // },
  {
    name: 'Sports',
    link: 'index/sports',
    component: SportsContainer,
    img: sportIcon,
    activeImg: sportActiveIcon,
    lazy: false,
  },
  {
    name: 'Live',
    link: 'index/live',
    component: LiveCasino,
    img: liveIcon,
    activeImg: liveActiveIcon,
  },
  {
    name: 'Casino',
    link: 'index/casino',
    component: Casino,
    img: casinoIcon,
    activeImg: casinoActiveIcon,
  },
  {
    name: 'Chat',
    link: 'index/chat',
    component: IMWebView,
    img: chatIcon,
    activeImg: chatActiveIcon,
    lazy: false,
  },

  // {
  //   name: 'ProxyHome',
  //   link: 'index/proxy-home',
  //   component: ProxyHome,
  //   svgIcon: AgentIcon,
  //   params: {
  //     hideBack: true,
  //   },
  // },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: meIcon,
    activeImg: meActiveIcon,
  },
];
const CusDefautTab = ({
  index,
  label,
  isFocused,
  options,
  onPress,
  count,
}: {
  index: number;
  label: string;
  isFocused: boolean;
  options: BottomTabNavigationOptions;
  onPress: (isFocused: boolean) => void;
  count?: number;
}) => {
  return (
    <NativeTouchableOpacity
      activeOpacity={0.8}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={() => {
        trackClick(`HOME_BOTTOMBAR_${TAB_OBJ[mainPageList[index].name]}_TAB`);
        onPress(isFocused);
      }}
      style={[
        theme.flex.center,
        theme.flex.flex1,
        theme.padding.tbs,
        theme.position.rel,
        theme.overflow.visible,
      ]}>
      <View style={[theme.position.rel]}>
        {
          <>
            <div
              className={` ${
                isFocused ? 'visible size-6' : 'invisible size-0'
              }`}>
              <Image
                className="size-full"
                src={mainPageList[index].activeImg!}
              />
            </div>
            <div
              className={` ${
                isFocused ? 'invisible size-0' : 'visible size-6'
              }`}>
              <Image className="size-full" src={mainPageList[index].img!} />
            </div>
          </>
        }
        {!!count && count > 0 && (
          <View
            style={[
              theme.flex.center,
              theme.position.abs,
              {
                paddingHorizontal: 5,
                paddingVertical: 2,
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 9999,
                backgroundColor: '#DC083B',
                left: 15,
                top: -4,
              },
            ]}>
            <Text fontSize={10} fontFamily="fontDin" blod color={'#FFF'}>
              {count > 99 ? '99+' : count}
            </Text>
          </View>
        )}
      </View>

      <Text
        blod={isFocused}
        fontSize={10}
        style={{
          color: isFocused ? theme.basicColor.primary : theme.basicColor.dark,
        }}>
        {label}
      </Text>
    </NativeTouchableOpacity>
  );
};

interface TabBarListItem {
  name: string;
  options?: BottomTabNavigationOptions;
}
interface TabBarListProps {
  list: TabBarListItem[];
  activeIndex: number;
}

export const TabBarList: FC<TabBarListProps> = ({list, activeIndex}) => {
  const [imCount, setImCount] = React.useState<number>(0);
  // const [token, setToken] = useState('');
  useEffect(() => {
    const countStr = localStorage.getItem('imCount') || '0';
    setImCount(Number(countStr));
    const sub = globalStore.chatNumSubject.subscribe(count => {
      setImCount(count);
    });
    // const tokenSub = globalStore.tokenSubject.subscribe(_token => {
    //   setToken(_token || '');
    // });
    return () => {
      sub.unsubscribe();
      // tokenSub.unsubscribe();
    };
  }, []);
  return (
    <Shadow containerViewProps={{id: 'tabbar-container'}} {...shadow}>
      {list.map((item, index) => {
        const onPress = (isFocused: boolean) => {
          if (isFocused) {
            return;
          }
          if (item.name === 'ProxyHome') {
            if (globalStore.userInfo?.isAgent !== 1) {
              return toAgentApply();
            }
          }
          // if (item.name === 'Chat' && !token) {
          //   goTo('Login', {
          //     backPage: homePage,
          //     sucessPage: homePage,
          //     sucessPageParams: {
          //       screen: 'Chat',
          //     },
          //   });
          //   return;
          // }
          if (item.name === 'Sports') {
            globalStore.openSports.next(undefined);
            return;
          }
          goTo(homePage, {
            screen: item.name,
          });
        };
        return (
          <ExposureWrap
            key={item.name}
            message={`HOME_BOTTOMBAR_${
              TAB_OBJ[mainPageList[index].name]
            }_EXPOSURE`}>
            <CusDefautTab
              key={item.name}
              index={index}
              label={item.name}
              isFocused={activeIndex === index}
              options={item.options || {}}
              onPress={onPress}
              count={item.name === 'Chat' ? imCount : 0}
            />
          </ExposureWrap>
        );
      })}
    </Shadow>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();

  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => {
        return (
          <TabBarList
            list={props.state.routes.map(route => {
              const {options} = props.descriptors[route.key];
              return {
                name: route.name,
                options: options,
              };
            })}
            activeIndex={props.state.index}
          />
        );
      }}
      backBehavior="none"
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
            lazy: v.lazy,
          }}
          initialParams={v.params}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
